import React,{useState} from "react";
import Menu from "./../components/menu"
import FilterMenu from "../components/filterMenu"
import Footer from "../components/footer"
import PlaceContainer from "../components/placeContainer";
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"
import data from"./../data/natural.json";
import Place from "./place";

const filters=["Çankaya","Etimesgut","Polatlı"]

export default function Natural(){
    const [currentPlace, setCurrentPlace] = useState({});
    const [place,isPlace]=useState(false)
    function findMyObject(b){
        let a = data.find(a=>a.id===b)
        return a
    }
    function findId (e){
        place?isPlace(false):isPlace(true);
        setCurrentPlace(findMyObject(e.currentTarget.id));

    };
    const [isMenu,setMenu]=useState(false)

    return(
        <div className="Museum">
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <Menu classNames="black" classNamess="black"></Menu>
            {!(place)&&<FilterMenu filters={filters} pageName={"Şehir / Doğal Güzellikler"} color={"#57C78B"} title="Harita"></FilterMenu>}
            {!(place)&&<PlaceContainer users={data} function={findId}></PlaceContainer>}
            {(place)&&<Place color={"#57C78B"} title={"Harita"} pageName={"Şehir / Doğal Güzellikler"} name={currentPlace.name} days={currentPlace.days} hours={currentPlace.hours} price={currentPlace.price} about={currentPlace.about}></Place>}
            
            <Footer></Footer>
        </div>
    )
}