import React, {useState}from "react";
import { Link } from "react-router-dom"
function Filter(props) {
    const filters = props.filters;
    const listItems = filters.map((filter) =>    <span>{filter}</span>  );
      return (<div className="filter">{listItems}</div>);
  }

export default function FilterMenu(props){
    const [isShown, setIsShown] = useState(false);
    return(
        <div className="FilterMenu">
            <div 
            onMouseLeave={() => setIsShown(false)}
            className="filter-container">
                <div className="filterMenu-label" style={{color:props.color}}>{props.pageName}</div>
                <div className="filter-label">
                    <span onMouseEnter={() => setIsShown(true)}>Filtrele</span>
                </div>
                    {isShown&&<Filter filters={props.filters}></Filter>}
            </div>
            <div className="filter-calender" style={{backgroundColor:props.color}}><Link to={props.title=="Takvim"?"/calender":"/map"} style={{color:"#FFFFFF"}}> {props.title}</Link></div>
        </div>
    )
}