import React from "react";
import AnkaradaLogo from "../images/AnkaradaLogo.png"
export default function Footer(){
    return(
        <div className="Footer">
            <div className="imgContainer"><img src={AnkaradaLogo} alt="ankarada"></img></div>
            <div className="menu">
                <span>Bize Ulaşın</span>
                <span>Hakkımızda</span>
                <span>Gizlilik</span>
            </div>
            <div className="social">
                <div className="images">
                    <div className="imgContainer"></div>
                    <div className="imgContainer"></div>
                    <div className="imgContainer"></div>
                </div>
                <div className="copyright">© Copyright 2023 ANKARADA | Tüm Hakları Saklıdır.</div>
            </div>
        </div>
    )
}