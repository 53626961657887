import React, { useState ,useEffect} from "react"
import Menu from "./../components/menu"
import FilterMenu from "../components/filterMenu"
import EventContainer from "../components/eventContainer"
import Footer from "../components/footer"
import Event from "./event"
import { db } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"
const filters=["aksiyon","macera","animasyon"]


function Exhibition(){
    const [users, setUsers] = useState([]);
    const [currentEvent, setCurrentEvent] = useState({});
    
    const usersCollectionRef = collection(db, "exhibition");
    useEffect(() => {
        const getUsers = async () => {
          const data = await getDocs(usersCollectionRef);
          setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
    
        getUsers();
      }, []);
    const [event,isEvent]=useState(false)
    function findMyObject(b){
        let a = users.find(a=>a.id===b)
        return a
    }
    function findId (e){
        event?isEvent(false):isEvent(true);
        setCurrentEvent(findMyObject(e.currentTarget.id));

    };
    function fetchEvent(b){
        event?isEvent(false):isEvent(true);
        setCurrentEvent(findMyObject(b))
    }
    let sergi=users.filter(type=>type.genre==="sergi")    
    let fuar=users.filter(type=>type.genre==="fuar")    
    let all="/exhibitionall"
    const [isMenu,setMenu]=useState(false)

    return(
        <div>
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <Menu classNames="black" classNamess="black"></Menu>
            {!(event) && <FilterMenu filters={filters} title="Takvim" pageName="Etkinlikler / Sergi & Fuar" color="#FFCA09"></FilterMenu>}
            {!(event) && <EventContainer users={sergi}  function={findId} containerName={"Sergi"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={fuar}  function={findId} containerName={"Fuar"} all={all}></EventContainer>}
            {event && <Event
                color={"#FFCA09"} title={"Takvim"} pageName={"Etkinlikler / Sergi & Fuar"} name={currentEvent.name} date={currentEvent.date} genre={currentEvent.genre} location={currentEvent.location} about={currentEvent.about} imgUrl={currentEvent.imgurl} price={currentEvent.price}
            ></Event>}
            <Footer></Footer>
        </div>
    )
}
export default Exhibition