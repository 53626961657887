import React from "react";
import PlaceContainerElement from "./placeContainerElement";

export default function PlaceContainer(props){
    return(
        <div className="placeContainer black">
           {props.users.map((event)=>{
                    return(
                        <PlaceContainerElement placeName={event.name} imgUrl={event.imgurl} function={props.function} id={event.id}></PlaceContainerElement>
                    )
                })}
        </div>
    )
}