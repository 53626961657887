import React from "react";
import { Link } from "react-router-dom";

function EventContainerElement(props){
    return(
        <div id={props.id} onClick={props.function} className="eventContainerElement">
            <div className="imgContainer"><img src={props.imgUrl} style={{objectFit:"contain",height:"100%",width:"100%"}}></img></div>
            <div>{props.eventName}</div>
        </div>
    )
}

export default function EventContainer(props){
    return(
        <div className="eventContainer">
            <div className="eventContainer-header">
                <div>{props.containerName}</div>
                <div className="showAll"><Link to={props.all} className="black">Tümünü Göster</Link></div>
            </div>
            <div className="elements">
                {props.users.slice(0,4).map((event)=>{
                    return(
                        <EventContainerElement eventName={event.name} imgUrl={event.imgurl} function={props.function} id={event.id}></EventContainerElement>
                    )
                })}
            </div>
        </div>
    )
}