import React from "react";
import './component.css';
import { Link } from "react-router-dom";
export default function DdPlace({classNames='',classNamess=''}){
    return(
        <div className={`ddPlaces dd ${classNamess}`}>
            <div className="col">
                <span><Link className={classNames}>Blog</Link></span>
                <span><Link to={"/museum"} className={classNames}>Müzeler</Link></span>
                <span><Link to={"/historical"} className={classNames}>Tarihi Yerler</Link></span>
                <span><Link to={"/natural"} className={classNames}>Doğal Güzellikler</Link></span>
            </div>
            <div className="col">
                <span><Link to={"/study"} className={classNames}>Çalışma Kafeleri</Link></span>
                <span><Link to={"/concept"} className={classNames}>Konsept Kafeler</Link></span>
                <span><Link className={classNames}>Yemek (Çok Yakında)</Link></span>
                <span><Link className={classNames}>Eğlence (çok yakında)</Link></span>

            </div>
        </div>
    )
    
}