import React from "react";

function MapMenuElement(props){
    return(
        <div className="element black" style={{backgroundColor:props.color, border:props.border}}>
            {props.name}
        </div>
    )
}

export default function MapMenu(){
    return(
        <div className="calenderMenu">
            <MapMenuElement name={"tümü"} color={"#FFFFFF"} border={"1px solid #707070"}></MapMenuElement>
            <MapMenuElement name={"Blog"} color={"#4D9DE0"}></MapMenuElement>
            <MapMenuElement name={"Müzeler"} color={"#7776BC"}></MapMenuElement>
            <MapMenuElement name={"Tarihi Yerler"} color={"#FEC1C9"}></MapMenuElement>
            <MapMenuElement name={"Doğal Güzellikler"} color={"#57C78B"}></MapMenuElement>
            <MapMenuElement name={"Study Kafeler"} color={"#FF8352"}></MapMenuElement>
            <MapMenuElement name={"Konsept Kafeler"} color={"#71D6CA"}></MapMenuElement>
        </div>
    )
}
