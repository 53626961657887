import FooterRouter from './../components/footer-router';
import Menu from './../components/menu';
import SearchBarMain from './../components/searchbar-main';
import "./../app.css"
import ankarada1 from "./../images/ankarada1.jpeg"
import arrow from "./../images/arrowDown.png"
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerWhite from "./../images/hamburgerWhite.png"
import { useState } from 'react';

function Home() {
  const [isMenu,setMenu]=useState(false)

  return (
    <>
    <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerWhite}></img></div>
    <div className="Home">
      <Menu classNames='white' classNamess='white 'classNamesss='ddHome'></Menu>
      {/*<SearchBarMain></SearchBarMain>*/}
      <FooterRouter></FooterRouter>
    </div>
      <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
    </>
  );
}

export default Home;
