import React from "react";

function CalenderMenuElement(props){
    return(
        <div id={props.id}className="element black" onClick={props.function} style={{backgroundColor:props.color, border:props.border}}>
            {props.name}
        </div>
    )
}

export default function CalenderMenu(props){
    return(
        <div className="calenderMenu">
            <CalenderMenuElement id={"all"}name={"tümü"} color={"#FFFFFF"} border={"1px solid #707070"} function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"cinema"}name={"sinema"} color={"#FF0E41"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"concert"}name={"konser"} color={"#FF510B"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"party"}name={"parti & festival"} color={"#FF0EBC"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"theatre"}name={"tiyatro"} color={"#09F04A"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"opera"}name={"opera & bale & orkestra"} color={"#CB0EFF"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"exhibition"}name={"sergi & fuar"} color={"#FFCA09"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"sport"}name={"spor"} color={"#0CBCFF"}function={props.function}></CalenderMenuElement>
            <CalenderMenuElement id={"standup"}name={"stand up"} color={"#B0EB23"}function={props.function}></CalenderMenuElement>
        </div>
    )
}
