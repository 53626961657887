import React ,{ useState } from "react";
import './component.css';
import DdEvents from "./ddEvents";
import DdPlace from "./ddPlaces";
import { Link } from "react-router-dom";

export default function Menu({classNames = '' , classNamess='',classNamesss=''}){
    const [isShownE, setIsShownE] = useState(false);
    const [isShownP, setIsShownP] = useState(false);
    return(
        <nav 
            className={classNames}
            onMouseLeave={() => {setIsShownE(false);setIsShownP(false)}}>
            
            <span><Link to="/" className={classNames}>ANKARADA</Link></span>
            <div className="dropdown">
                <span 
                onMouseEnter={() => {setIsShownE(true);setIsShownP(false)}}
                className="Events">Etkinlikler</span>
                <span 
                onMouseEnter={() => {setIsShownP(true);setIsShownE(false)}}
                className="Places">Şehir</span>
            </div>
            {isShownE&&<DdEvents classNames={classNamess}classNamess={classNamesss}></DdEvents>}
            {isShownP&&<DdPlace classNames={classNamess} classNamess={classNamesss}></DdPlace>}
        </nav>
    )
}