import React from "react";
import './component.css';
import { Link } from "react-router-dom";
export default function DdEvents({classNamess='',classNames=''}){
    return(
        <div className={`ddEvents dd ${classNamess}`}>
            <div className="col">
                <span><Link to="/cinema" className={classNames}>Sinema</Link></span>
                <span><Link to="/concert" className={classNames}>Konser</Link></span>
                <span><Link to="/party" className={classNames} >Parti & Festival</Link></span>
                <span><Link to="/theatre" className={classNames} >Tiyatro</Link></span>
                <span><Link to="/opera" className={classNames} >Opera & Bale & Orkestra</Link></span>
            </div>
            <div className="col">
                <span><Link to="/exhibition" className={classNames} >Sergi & Fuar</Link></span>
                <span><Link to="/sport" className={classNames} >Spor</Link></span>
                <span><Link to="/standup" className={classNames} >Stand Up</Link></span>
                <span>Workshoplar (çok yakında)</span>

            </div>
        </div>
    )
    
}