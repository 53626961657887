import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMemo } from "react";
export default function CustomMap() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      });
    
      if (!isLoaded) return <div>Loading...</div>;
    return (
        <Map/>
    )
};
function Map() {
    const center = useMemo(() => ({ lat: 39.88700343443834, lng: 32.77892975712011 }), []);
    return (
      <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
        <Marker position={{lat:39,lng:32}} />
      </GoogleMap>
    );
  }