import "./app.css"
import Cinema from "./pages/cinema";
import Historical from "./pages/historical";
import Home from './pages/home';
import Museum from "./pages/muesum";
import Natural from "./pages/natural";
import Study from "./pages/study";
import Concept from "./pages/concept"
import LocationMap from "./pages/map";
import Event from "./pages/event";
import ConcertAll from "./pages/concertAll";
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Calender from "./pages/calender";
import Concert from "./pages/concert";
import Party from "./pages/party";
import PartyAll from "./pages/partyAll";
import Opera from "./pages/opera";
import OperaAll from "./pages/operaAll";
import Theatre from "./pages/theatre";
import TheatreAll from "./pages/theatreAll";
import Exhibition from "./pages/exhibition";
import ExhibitionAll from "./pages/exhibitionAll";
import Sport from "./pages/sport";
import SportAll from "./pages/sportAll";
import StandUp from "./pages/standup";
import StandUpAll from "./pages/standupAll";
import Place from "./pages/place";
import NewCalender from "./pages/newCalendar";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/cinema' element={<Cinema />} />
            <Route exact path='/concept' element={<Concept />} />
            <Route exact path='/historical' element={<Historical />} />
            <Route exact path='/natural' element={<Natural />} />
            <Route exact path='/study' element={<Study />} />
            <Route exact path='/museum' element={<Museum />} />
            <Route exact path='/calender' element={<Calender />} />
            <Route exact path='/map' element={<LocationMap />} />
            <Route exact path='/event' element={<Event />} />
            <Route exact path='/concert' element={<Concert />} />
            <Route exact path='/concertall' element={<ConcertAll />} />
            <Route exact path='/party' element={<Party />} />
            <Route exact path='/partyall' element={<PartyAll />} />
            <Route exact path='/opera' element={<Opera />} />
            <Route exact path='/operaall' element={<OperaAll />} />
            <Route exact path='/theatre' element={<Theatre />} />
            <Route exact path='/theatreall' element={<TheatreAll />} />
            <Route exact path='/exhibition' element={<Exhibition />} />
            <Route exact path='/exhibitionall' element={<ExhibitionAll />} />
            <Route exact path='/sport' element={<Sport />} />
            <Route exact path='/sportall' element={<SportAll />} />
            <Route exact path='/standup' element={<StandUp />} />
            <Route exact path='/standupall' element={<StandUpAll />} />
            <Route exact path='/place' element={<Place />} />
            <Route exact path='/newcalendar' element={<NewCalender />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
