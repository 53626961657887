import React, { useState ,useEffect} from "react"
import Menu from "./../components/menu"
import FilterMenu from "../components/filterMenu"
import EventContainer from "../components/eventContainer"
import Footer from "../components/footer"
import Event from "./event"
import { db } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"

const filters=["aksiyon","macera","animasyon"]


function Concert(){
    const [users, setUsers] = useState([]);
    const [currentEvent, setCurrentEvent] = useState({});
    
    const usersCollectionRef = collection(db, "concert");
    useEffect(() => {
        const getUsers = async () => {
          const data = await getDocs(usersCollectionRef);
          setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
    
        getUsers();
      }, []);
    const [event,isEvent]=useState(false)
    function findMyObject(b){
        let a = users.find(a=>a.id===b)
        return a
    }
    function findId (e){
        event?isEvent(false):isEvent(true);
        setCurrentEvent(findMyObject(e.currentTarget.id));

    };
    function fetchEvent(b){
        event?isEvent(false):isEvent(true);
        setCurrentEvent(findMyObject(b))
    }
    let pop=users.filter(type=>type.genre==="pop")    
    let rock=users.filter(type=>type.genre==="rock")    
    let hiphop=users.filter(type=>type.genre==="hiphop")    
    let caz=users.filter(type=>type.genre==="caz")
    let klasik=users.filter(type=>type.genre==="klasik")
    let alternatif=users.filter(type=>type.genre==="alternatif")
    let diger=users.filter(type=>type.genre==="diger")
    let all="/concertall"
    const [isMenu,setMenu]=useState(false)

    return(
        <div>
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <Menu classNames="black" classNamess="black"></Menu>
            {!(event) && <FilterMenu filters={filters} title="Takvim" pageName="Etkinlikler / Konser" color="#FF510B"></FilterMenu>}
            {!(event) && <EventContainer users={users}  function={findId} containerName={"En Yakındakiler"} all={all} ></EventContainer>}
            {!(event) && <EventContainer users={users}  function={findId} containerName={"Tüm Konserler"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={pop}  function={findId} containerName={"Pop"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={rock}  function={findId} containerName={"Rock"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={hiphop}  function={findId} containerName={"Rap"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={caz}  function={findId} containerName={"Caz"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={klasik}  function={findId} containerName={"Klasik"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={alternatif}  function={findId} containerName={"Alternatif"} all={all}></EventContainer>}
            {!(event) && <EventContainer users={diger}  function={findId} containerName={"Diğer"} all={all}></EventContainer>}
            {event && <Event
                color={"#FF0E41"} title={"Takvim"} pageName={"Etkinlikler/Konser"} name={currentEvent.name} date={currentEvent.date} genre={currentEvent.genre} location={currentEvent.location} about={currentEvent.about} imgUrl={currentEvent.imgurl} price={currentEvent.price}
            ></Event>}
            <Footer></Footer>
        </div>
    )
}
export default Concert