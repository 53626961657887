// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAecvqsPv2XdyYGxgvpiymzDYzUXvv6ybg",
  authDomain: "ankaram-cd839.firebaseapp.com",
  projectId: "ankaram-cd839",
  storageBucket: "ankaram-cd839.appspot.com",
  messagingSenderId: "1052382962630",
  appId: "1:1052382962630:web:81d0daa6f9eb876ac5c8c7",
  measurementId: "G-QFXNZHZXDM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage =getStorage(app);