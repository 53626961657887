import React,{useState} from "react"
import { Link } from "react-router-dom"
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"
import '../components/component.css';

function Filter(props){
    return(
        <div className="filter-calender white" style={{backgroundColor:props.color,fontFamily:"'Lato', sans-serif"}}><Link to={props.title=="Takvim"?"/calender":"/map"} style={{color:"#FFFFFF"}}> {props.title}</Link></div>
    )
}

function Place(props){
    const [isMenu,setMenu]=useState(false)
    return(
        <div style={{fontFamily:"'Lato', sans-serif"}}>
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <div style={{display:"flex"}}>
                <div className="filterMenu-label" style={{color:props.color,marginLeft:"3vh"}}>{props.pageName}</div>
                <Filter style={{position:"absolute",right:"2vh"}} color={props.color} title={props.title}></Filter>
            </div>
            <div className="eventch">
                <div style={{display:"flex",flexDirection:"column"}}>
                    <div style={{marginLeft:"3vw",fontSize:"4vh",marginBottom:"3vh"}}>{props.name}</div>
                    <div style={{display:"flex"}}>
                        <div className="imgContainer" style={{marginLeft:"3vw"}}><img style={{objectFit:"contain",height:"100%",width:"100%"}} src={props.imgUrl}></img> </div>
                        <div className="placeInfoAbout">
                            <span style={{color:props.color,fontSize:"4vh"}}>Hakkında</span>
                            <p>{props.about}</p>
                        </div>
                    </div>
                    <div className="placeInfo" style={{display:"flex",justifyContent:"space-around",width:"100vw",marginTop:"5vh",rowGap:"5vh"}}>
                        <div className="columnCenter"><span style={{fontSize:"4vh"}}>Çalışma Günleri</span>{props.days.map((x)=>{return(<p>{x}</p>)})}</div>
                        <div className="columnCenter"><span style={{fontSize:"4vh"}}>Çalışma Saatleri</span>{props.days.map((x)=>{return(<p>{props.hours}</p>)})}</div>
                        <div className="columnCenter"><span style={{fontSize:"4vh"}}>Konum</span></div>
                        <div className="columnCenter"><span style={{fontSize:"4vh"}}>Giriş Ücreti</span><p>{props.price}</p></div>
                    </div>
                </div>
            </div>
                
        </div>
    )
}
export default Place