import React,{useState} from "react"
import { Link } from "react-router-dom"
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"

function Filter(props){
    return(
        <div className="filter-calender white" style={{backgroundColor:props.color,fontFamily:"'Lato', sans-serif"}}><Link to={props.title=="Takvim"?"/calender":"/map"} style={{color:"#FFFFFF"}}> {props.title}</Link></div>
    )
}

function Event(props){
    const [isMenu,setMenu]=useState(false)
    return(
        <div className="event">
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <div className="container">
                <div className="filterMenu-label" style={{color:props.color}}>{props.pageName}</div>
                <Filter style={{position:"absolute",right:"5vh"}} color={props.color} title={props.title}></Filter>
            </div>
            <div className="eventch">
                <div>
                    <div className="imgContainer"><img style={{objectFit:"contain",height:"100%",width:"100%"}} src={props.imgUrl}></img> </div>
                </div>
                <div className="container" style={{display:"flex",justifyContent:"space-around",flexDirection:"column"}}>
                    {!(props.name==="")&&<p className="title">Etkinlik Adı:{props.name}</p>}
                    {!(props.date==="")&&<p className="spec">Tarih:{props.date}</p>}
                    {!(props.price==="")&&<p className="spec">Fiyat:{props.price}</p>}
                    {!(props.genre==="")&&<p className="spec">Tür:{props.genre}</p>}
                    {!(props.location==="")&&<p className="spec">Yer:{props.location}</p>}
                </div>
            </div>
            <div className="about">
                <h1 style={{color:props.color}}>Hakkında</h1>
                {!(props.about==="")&&<p>
                    {props.about}
                </p>}
            </div>
        </div>
    )
}
export default Event