import React, { useState } from "react";
import './component.css';
import { Link } from "react-router-dom";
import arrowDown from "./../images/arrowDown.png"
import arrowUp from "./../images/arrowUp.png"
export default function MobileNavbar({isMenu,onChange,img}){
    const[isEvent,setIsEvent]=useState(false)
    const[isPlace,setIsPlace]=useState(false)
    const[arrow1,setArrow1]=useState(arrowDown)
    const[arrow2,setArrow2]=useState(arrowDown)
    function eventClick(){
        if(isEvent===false){setIsEvent(true);setIsPlace(false);setArrow1(arrowUp);setArrow2(arrowDown)}
        if(isEvent===true){setIsEvent(false);setArrow1(arrowDown);}
    }
    function placeClick(){
        if(isPlace===false){setIsPlace(true);setIsEvent(false);setArrow2(arrowUp);setArrow1(arrowDown)}
        if(isPlace===true){setIsPlace(false);setArrow2(arrowDown)}
    }

    return(
        <div className={`mobile-navbar mobile ${isMenu && "open"}`}>
            <div
                className=""
                onClick={() => onChange(false)}
            >
                <img src={img} style={{width:"5vw",height:"3.5vh", position:"absolute",top:"3vh",left:"3vw",color:"yellow"}}></img>
            </div>
            <div><img src=""></img></div>
            <div className="header black">ANKARADA</div>
            <div className="nav black"><span>Etkinlikler</span><img src={arrow1} onClick={eventClick}></img></div>
            {isEvent&&<div className="list black">
                <Link className="black link" to={"/cinema"}>Sinema</Link>
                <Link className="black link" to={"/concert"}>Konser</Link>
                <Link className="black link" to={"/party"}>Parti & Festival</Link>
                <Link className="black link" to={"/theatre"}>Tiyatro</Link>
                <Link className="black link" to={"/opera"}>Opera & Orkestra & Bale</Link>
                <Link className="black link" to={"/exhibition"}>Sergi & Fuar</Link>
                <Link className="black link" to={"/sport"}>Spor</Link>
                <Link className="black link" to={"/standup"}>Stand Up</Link>
                </div>}
            <div className="nav black"><span>Şehir</span><img src={arrow2} onClick={placeClick}></img></div>
            {isPlace&&<div className="list black">
                <Link to={"/home"} className="black link">Blog</Link>
                <Link to={"/museum"} className="black link">Müzeler</Link>
                <Link to={"/historical"} className="black link">Tarihi Yerler</Link>
                <Link to={"/natural"}className="black link">Doğal Güzellikler</Link>
                <Link to={"/study"} className="black link">Çalışma Kafeleri</Link>
                <Link to={"/concept"}className="black link">Konsept Kafeler</Link>
                </div>}

            <div className="nav black"><Link to={"/calender"}className="black link">Takvim</Link></div>
            <div className="nav black"><Link to={"/map"}className="black link">Harita</Link></div>
        </div>
    )
}