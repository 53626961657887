import React,{ useState ,useEffect} from "react"
import { db } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import Menu from "./../components/menu"
import Footer from "../components/footer"
import Event from "./event"

import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"
export default function NewCalender(){

    const [cinema, setCinema] = useState([]);
    const cinemaCollectionRef = collection(db, "movies");
    useEffect(() => {
        const getCinema = async () => {
          const data = await getDocs(cinemaCollectionRef);
          setCinema(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Sinema", color:"#FF0E41"})));
        };
    
        getCinema();
      }, []);
    const [concert, setConcert] = useState([]);
    const concertCollectionRef = collection(db, "concert");
    useEffect(() => {
        const getConcert = async () => {
          const data = await getDocs(concertCollectionRef);
          setConcert(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Konser", color:"#FF510B"})));
        };
    
        getConcert();
      }, []);
    const [exhibition, setexhibition] = useState([]);
    const exhibitionCollectionRef = collection(db, "exhibition");
    useEffect(() => {
        const getExhibition = async () => {
          const data = await getDocs(exhibitionCollectionRef);
          setexhibition(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Sergi & Fuar", color:"#FFCA09"})));
        };
    
        getExhibition();
      }, []);
    const [party, setparty] = useState([]);
    const partyCollectionRef = collection(db, "party");
    useEffect(() => {
        const getParty = async () => {
          const data = await getDocs(partyCollectionRef);
          setparty(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Parti & Festival", color:"#FF0EBC"})));
        };
    
        getParty();
      }, []);
    const [theatre, settheatre] = useState([]);
    const theatreCollectionRef = collection(db, "theatre");
    useEffect(() => {
        const getTheatre = async () => {
          const data = await getDocs(theatreCollectionRef);
          settheatre(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Tiyatro", color:"#09F04A"})));
        };
    
        getTheatre();
      }, []);
    const [opera, setopera] = useState([]);
    const operaCollectionRef = collection(db, "opera");
    useEffect(() => {
        const getOpera = async () => {
          const data = await getDocs(operaCollectionRef);
          setopera(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"opera", color:"#CB0EFF"})));
        };
    
        getOpera();
      }, []);
    const [sport, setsport] = useState([]);
    const sportCollectionRef = collection(db, "sport");
    useEffect(() => {
        const getSport = async () => {
          const data = await getDocs(sportCollectionRef);
          setsport(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Spor", color:"#0CBCFF"})));
        };
    
        getSport();
      }, []);
    const [standup, setstandup] = useState([]);
    const standupCollectionRef = collection(db, "standup");
    useEffect(() => {
        const getStandup = async () => {
          const data = await getDocs(standupCollectionRef);
          setstandup(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Stand Up", color:"#B0EB23"})));
        };
    
        getStandup();
      }, []);
      const all=[...cinema, ...concert,...exhibition,...party,...theatre,...opera,...sport,...standup];
    // Get the current date
    function getCurrentDates(){

        const currentDate = new Date();
    
        // Get the year and month of the current date
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
    
        // Get the number of days in the current month
        const numDays = new Date(year, month + 1, 0).getDate();
    
        // Create an array to store the dates
        const dates = [];
    
        // Loop through the days of the month and add them to the array
        for (let i = 1; i <= numDays; i++) {
        const date = new Date(year, month, i);
        dates.push(date);
        }
    
        // Print the dates array to the console
        return(dates)
    }
    function getCurrentMonth(){
      const currentDate=new Date();
      const year = currentDate.getFullYear().toString();
      const month = currentDate.toLocaleString('tr-TR', { month: 'long' });
      return(month+" "+year)

    }
    var currentDays=getCurrentDates()
    var daysOfWeek=["Pazartesi","Salı","Çarşamba","Perşembe","Cuma","Cumartesi","Pazar"]
    var daysOfWeekMobile=["P","S","Ç","P","C","C","P"]
    function addOtherDays(){
        for(let i = 0; i < (currentDays[0].getDate()-1) ; i++){
            currentDays.unshift("")
        }
       for(let i = 0; i < (38-currentDays.length) ; i++){
         currentDays.push("")
      }
        
    }
    addOtherDays()
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
    const [event,isEvent]=useState(false)
    const [currentEvent, setCurrentEvent] = useState({});
    function findMyObject(b){
      let a = all.find(a=>a.id===b)
      return a
  }
    function findId (e){
      event?isEvent(false):isEvent(true);
      setCurrentEvent(findMyObject(e.currentTarget.id));

  };
  const [isMenu,setMenu]=useState(false)
    return(
        <>
        <div className='mobile' onClick={() => setMenu(true)} style={{width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
        <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
        {!(event)&&<div className="calendarCanvas">
            <div className="title">
                <span>Takvim</span>
                <span className="month">{getCurrentMonth()}</span>
            </div>
            <div className="contentCanvas">
                <div className="contentGrid browser">
                    {daysOfWeek.map((event)=>{return(<div>{event}</div>)})}
                </div>
                <div className="contentGrid mobile">
                    {daysOfWeekMobile.map((event)=>{return(<div>{event}</div>)})}
                </div>
                <div className="contentGrid">
                    {   
                        currentDays.map((event)=>{
                          var date=!(event==="")&&event.toLocaleDateString().split(".").reverse().join("-")
                          if(event===""){date="boş"}
                          var day=!(date==="boş")&&date.substring(date.length-2)
                            return(
                                <div className="contentBox">
                                    {day}
                                    <div className="dotBox" >
                                    {   
                                        
                                        all.map((e)=>{
                                          if(e.date===date){
                                            return(
                                              <div className="dateDot" style={{backgroundColor:(e.date===date)&&e.color}} onClick={findId} id={e.id}>
                                                
                                              </div>
                                            )
                                          }
                                        })
                                    }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
                <div className="calendarLegend">
                    <span style={{color:"#FF0E41"}}>Sinema</span>
                    <span style={{color:"#FF510B"}}>Konser</span>
                    <span style={{color:"#FF0EBC"}}>Parti & Festival</span>
                    <span style={{color:"#09F04A"}}>Tiyatro</span>
                    <span style={{color:"#CB0EFF"}}>Opera & Bale & Orkestra</span>
                    <span style={{color:"#FFCA09"}}>Sergi & Fuar</span>
                    <span style={{color:"#0CBCFF"}}>Spor</span>
                    <span style={{color:"#B0EB23"}}>Stand Up</span>
                </div>
        </div>}
        {event&&<div className="calender">
            <Menu classNames="black" classNamess="black"></Menu>
            <Event
                color={currentEvent.color} title={"Takvim"} pageName={"Etkinlikler/"+currentEvent.eventType} name={currentEvent.name} date={currentEvent.date} genre={currentEvent.genre} location={currentEvent.location} about={currentEvent.about} imgUrl={currentEvent.imgurl} price={currentEvent.price}
            ></Event>
            <Footer></Footer>
          </div>}
        </>

    )
    
}