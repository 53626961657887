import React from "react";
import "./component.css"
import { Link } from "react-router-dom";
export default function FooterRouter(){
    return(
        <div className="fRouter white">
            <span className="fRouter-element">Duyurular</span>
            <span className="fRouter-element"><Link to="/calender" className="white">Takvim</Link></span>
            <span className="fRouter-element">Harita</span>
        </div>
    )
}