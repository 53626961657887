import React ,{useState}from "react";
import Footer from "../components/footer";
import MapMenu from "../components/MapMenu";
import Menu from "../components/menu";
import MobileNavbar from '../components/mobile-navbar';
import arrowBack from "./../images/arrowBack.png"
import hamburgerBlack from "./../images/hamburgerBlack.png"
import CustomMap from "../components/customMap";

export default function LocationMap(){
    const [isMenu,setMenu]=useState(false)

    return(
        <div className="calender">
            <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
            <Menu classNames="black" classNamess="black"></Menu>
            <div className="container">
                <MapMenu></MapMenu>
                <CustomMap></CustomMap>
            </div>
            <Footer></Footer>
        </div>
    )
}