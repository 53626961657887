import React, { useState ,useEffect} from "react";
import CalenderEvents from "../components/calenderEvents";
import CalenderMenu from "../components/calenderMenu";
import Menu from "./../components/menu"
import Footer from "../components/footer"
import Event from "./event"
import { db } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import arrowBack from "./../images/arrowBack.png"
import MobileNavbar from '../components/mobile-navbar';
import hamburgerBlack from "./../images/hamburgerBlack.png"

export default function Calender(){
    const [cinema, setCinema] = useState([]);
    const cinemaCollectionRef = collection(db, "movies");
    useEffect(() => {
        const getCinema = async () => {
          const data = await getDocs(cinemaCollectionRef);
          setCinema(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Sinema", color:"#FF0E41"})));
        };
    
        getCinema();
      }, []);
    const [concert, setConcert] = useState([]);
    const concertCollectionRef = collection(db, "concert");
    useEffect(() => {
        const getConcert = async () => {
          const data = await getDocs(concertCollectionRef);
          setConcert(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Konser", color:"#FF510B"})));
        };
    
        getConcert();
      }, []);
    const [exhibition, setexhibition] = useState([]);
    const exhibitionCollectionRef = collection(db, "exhibition");
    useEffect(() => {
        const getExhibition = async () => {
          const data = await getDocs(exhibitionCollectionRef);
          setexhibition(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Sergi & Fuar", color:"#FFCA09"})));
        };
    
        getExhibition();
      }, []);
    const [party, setparty] = useState([]);
    const partyCollectionRef = collection(db, "party");
    useEffect(() => {
        const getParty = async () => {
          const data = await getDocs(partyCollectionRef);
          setparty(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Parti & Festival", color:"#FF0EBC"})));
        };
    
        getParty();
      }, []);
    const [theatre, settheatre] = useState([]);
    const theatreCollectionRef = collection(db, "theatre");
    useEffect(() => {
        const getTheatre = async () => {
          const data = await getDocs(theatreCollectionRef);
          settheatre(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Tiyatro", color:"#09F04A"})));
        };
    
        getTheatre();
      }, []);
    const [opera, setopera] = useState([]);
    const operaCollectionRef = collection(db, "opera");
    useEffect(() => {
        const getOpera = async () => {
          const data = await getDocs(operaCollectionRef);
          setopera(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"opera", color:"#CB0EFF"})));
        };
    
        getOpera();
      }, []);
    const [sport, setsport] = useState([]);
    const sportCollectionRef = collection(db, "sport");
    useEffect(() => {
        const getSport = async () => {
          const data = await getDocs(sportCollectionRef);
          setsport(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Spor", color:"#0CBCFF"})));
        };
    
        getSport();
      }, []);
    const [standup, setstandup] = useState([]);
    const standupCollectionRef = collection(db, "standup");
    useEffect(() => {
        const getStandup = async () => {
          const data = await getDocs(standupCollectionRef);
          setstandup(data.docs.map((doc) => ({ ...doc.data(), id: doc.id ,eventType:"Stand Up", color:"#B0EB23"})));
        };
    
        getStandup();
      }, []);
      const all=[...cinema, ...concert,...exhibition,...party,...theatre,...opera,...sport,...standup];
      const [event,isEvent]=useState(false)
      const [currentEvent, setCurrentEvent] = useState({});
      function findMyObject(b){
        let a = all.find(a=>a.id===b)
        return a
    }
      function findId (e){
        event?isEvent(false):isEvent(true);
        setCurrentEvent(findMyObject(e.currentTarget.id));

    };
    const [eventType,setEventType]=useState(all)
    function findMyEventType(e){
        let current=e.currentTarget.id
        if(current==="all"){setEventType(all)}
        else if(current==="cinema"){setEventType(cinema)}
        else if(current==="concert"){setEventType(concert)}
        else if(current==="party"){setEventType(party)}
        else if(current==="theatre"){setEventType(theatre)}
        else if(current==="opera"){setEventType(opera)}
        else if(current==="exhibition"){setEventType(exhibition)}
        else if(current==="sport"){setEventType(sport)}
        else if(current==="standup"){setEventType(standup)}
    }
    const [isMenu,setMenu]=useState(false)

    return(
        <div className="calender">
              <div className='mobile' onClick={() => setMenu(true)} style={{position:"absolute",width:"4vh",height:"4vh",top:"3vh",left:"3vw"}}><img style={{height:"100%",width:"100%"}}src={hamburgerBlack}></img></div>

            <Menu classNames="black" classNamess="black"></Menu>
            {!(event)&&<div className="container">
                <CalenderMenu function={findMyEventType}></CalenderMenu>
                <CalenderEvents users={eventType} name={all.name} function={findId}></CalenderEvents>
            </div>}
            {event && <Event
                color={currentEvent.color} title={"Takvim"} pageName={"Etkinlikler/"+currentEvent.eventType} name={currentEvent.name} date={currentEvent.date} genre={currentEvent.genre} location={currentEvent.location} about={currentEvent.about} imgUrl={currentEvent.imgurl} price={currentEvent.price}
            ></Event>}
            <Footer></Footer>
            <MobileNavbar img={arrowBack} isMenu={isMenu} onChange={setMenu}></MobileNavbar>
              
        </div>
    )
}