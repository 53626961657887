import React from "react";

function CalenderEvent(props){
    return(
        <div id={props.id} onClick={props.function} className="calenderEvent black">
            <div style={{
                border:` 15px solid ${props.color}`,
                opacity: 1,
                borderRadius:"1vh"
            }}></div>
            <div className="imgContainer"><img src={props.imgUrl} style={{objectFit:"contain",height:"100%",width:"100%"}}></img></div>
            <div className="textContainer">
                <h1>{props.title}</h1>
                <p style={{justifyContent:"flex-end"}}>{props.description}</p>
            </div>
        </div>
    )
}


export default function CalenderEvents(props){
    return(
        <div className="calenderEvents">
            {props.users.map((event)=>{
                return(
                    <CalenderEvent title={event.name} description={event.about} color={event.color} imgUrl={event.imgurl} function={props.function} id={event.id}></CalenderEvent>
                )
            })}

        </div>
    )
}